import React, { useState, useEffect } from "react";
import axios from "axios";
import slugify from "slugify";

import Button from "../Button/Button";

import {
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
	MDBCardTitle,
	MDBCardText,
	MDBBtn,
	MDBIcon,
} from "mdbreact";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function CardEditais() {
	const [editaisOld, setEditaisOld] = useState(null);
	const [editais, setEditais] = useState(null);

	useEffect(() => {
		axios
			.get(`https://servico.feevale.br/site/v1/editais/0/10/escola`)
			.then((res) => {
				let result = res.data;
				setEditaisOld({ result });
			});

		axios.get(`https://servico.feevale.br/site/v2/editais/`).then((res) => {
			let result = res.data.filter((e) => {
				if (
					e.Configuracoes.AgruTag.Value != null &&
					e.Configuracoes.AgruTag.Value.includes("escola") &&
					Date.parse(e.Published) <= Date.now()
				)
					return e;
			});

			setEditais({ result });
		});
	}, []);

	// Define os botões de avanço e retorno do carousel
	const ButtonGroup = ({ next, previous }) => {
		if (
			(editaisOld ? editaisOld.result.length : 0) +
				(editais ? editais.result.length : 0) <=
			1
		) {
			return <></>;
		} else {
			return (
				<>
					{(editaisOld ? editaisOld.result.length : 0) +
						(editais ? editais.result.length : 0) <=
						3 && (
						<>
							<MDBIcon
								className="text-black d-none d-md-block fgprimary-gradient"
								style={{
									position: "absolute",
									zIndex: 1000,
									top: "40%",
									left: "-4%",
									backgroundColor: "transparent",
									cursor: "pointer",
								}}
								icon="chevron-left"
								size="3x"
								onClick={() => previous()}
							/>
							<MDBIcon
								className="text-black d-none d-md-block fgprimary-gradient"
								style={{
									position: "absolute",
									zIndex: 1000,
									top: "40%",
									right: "-4%",
									backgroundColor: "transparent",
									cursor: "pointer",
								}}
								icon="chevron-right"
								size="3x"
								onClick={() => next()}
							/>
						</>
					)}
					<MDBRow
						className="d-flex d-md-none"
						style={{
							position: "absolute",
							top: "-20%",
							left: "50%",
							transform: "translateX(-42%)",
						}}
					>
						<MDBCol className="d-flex p-0 pr-4">
							<MDBBtn
								className="arrow-carrousel m-0"
								onClick={() => previous()}
							>
								<span className="d-flex">
									<MDBIcon icon="chevron-left" className="p-2" />
								</span>
							</MDBBtn>
						</MDBCol>
						<MDBCol className="d-flex p-0 pl-4">
							<MDBBtn className="arrow-carrousel m-0" onClick={() => next()}>
								<span className="d-flex">
									<MDBIcon icon="chevron-right" className="p-2" />
								</span>
							</MDBBtn>
						</MDBCol>
					</MDBRow>
				</>
			);
		}
	};

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1025 },
			items: 3,
			slidesToSlide: 3, // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 465 },
			items: 2,
			slidesToSlide: 2, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
	};

	if (
		(editaisOld !== null && editaisOld.result.length > 0) ||
		(editais !== null && editais.result.length > 0)
	) {
		return (
			<MDBContainer className="pt-3">
				<MDBRow>
					<MDBCol className="px-0">
						<h3 className="fgprimary-gradient">editais</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow
					className={
						(editaisOld ? editaisOld.result.length : 0) +
							(editais ? editais.result.length : 0) <=
						1
							? "pt-0 mt-0"
							: "pt-5 mt-3 pt-md-0 mt-md-0"
					}
				>
					<MDBCol>
						<Carousel
							swipeable={true}
							draggable={false}
							showDots={false}
							responsive={responsive}
							ssr={true} // means to render carousel on server-side.
							infinite={
								(editaisOld ? editaisOld.result.length : 0) +
									(editais ? editais.result.length : 0) <
								1
									? false
									: true
							}
							autoPlay={
								(editaisOld ? editaisOld.result.length : 0) +
									(editais ? editais.result.length : 0) <
								1
									? false
									: true
							}
							autoPlaySpeed={3000}
							keyBoardControl={true}
							transitionDuration={500}
							arrows={false}
							renderButtonGroupOutside={true}
							customButtonGroup={<ButtonGroup />}
							containerClass="carousel-container py-1"
						>
							{editaisOld?.result?.map((edital, index) => {
								let dataInicial = new Date(
									edital.dataInicial
								).toLocaleDateString("pt-br", {
									year: "numeric",
									month: "numeric",
									day: "numeric",
								});

								let dataFinal = new Date(edital.dataFinal).toLocaleDateString(
									"pt-br",
									{
										year: "numeric",
										month: "numeric",
										day: "numeric",
									}
								);

								return (
									<MDBCard className="mx-1 mx-md-3 h-100" key={edital.titulo}>
										<MDBCardBody>
											<MDBCardTitle className="h6 text-center text-md-left">
												De{" "}
												<span
													className="fgprimary-gradient"
													style={{ fontWeight: 500 }}
												>
													{dataInicial}
												</span>{" "}
												a{" "}
												<span
													className="fgprimary-gradient"
													style={{ fontWeight: 500 }}
												>
													{dataFinal}
												</span>
											</MDBCardTitle>
											<hr className="p-0 m-0" />
											<MDBCardText className="pt-3">
												<a
													href={edital.tituloUrl}
													target="_blank"
													className="text-decoration-none"
													rel="noreferrer"
												>
													<h5 className="colorLetter">{edital.titulo}</h5>
												</a>
											</MDBCardText>
										</MDBCardBody>
										<MDBContainer>
											<MDBRow>
												<MDBCol className="pb-2">
													<Button
														type="institucional"
														to={edital.tituloURL}
														text="saiba mais"
													/>
												</MDBCol>
											</MDBRow>
										</MDBContainer>
									</MDBCard>
								);
							})}
							{editais?.result?.map((edital, index) => {
								let dataInicial = new Date(edital.Published);
								dataInicial.setDate(dataInicial.getDate() - 1);
								let today = new Date();

								if (dataInicial > today) {
									return <></>;
								} else {
									return (
										<MDBCard className="mx-1 mx-md-3 h-100" key={edital.Title}>
											<MDBCardBody>
												<MDBCardTitle className="h6 text-center text-md-left">
													<span
														className="fgprimary-gradient"
														style={{ fontWeight: 500 }}
													>
														{edital.Configuracoes.DescData.Value}
													</span>
												</MDBCardTitle>
												<hr className="p-0 m-0" />
												<MDBCardText className="pt-3">
													<a
														href={
															"https://editais.feevale.br/editais/" +
															slugify(
																edital.Title.replaceAll("ª", "").replaceAll(
																	"º",
																	""
																),
																{
																	remove: /[[\]{}|§º°ª¹²³¬?*+¨^~.\\/()'"!:@]/g,
																	lower: true,
																}
															).toLowerCase()
														}
														target="_blank"
														className="text-decoration-none"
														rel="noreferrer"
													>
														<h5 className="colorLetter">{edital.Title}</h5>
													</a>
												</MDBCardText>
											</MDBCardBody>
											<MDBContainer>
												<MDBRow>
													<MDBCol className="pb-2">
														<Button
															type="institucional"
															to={
																"https://editais.feevale.br/editais/" +
																slugify(
																	edital.Title.replaceAll("ª", "").replaceAll(
																		"º",
																		""
																	),
																	{
																		remove:
																			/[[\]{}|§º°ª¹²³¬?*+¨^~.\\/()'"!:@]/g,
																		lower: true,
																	}
																).toLowerCase()
															}
															text="saiba mais"
														/>
													</MDBCol>
												</MDBRow>
											</MDBContainer>
										</MDBCard>
									);
								}
							})}
						</Carousel>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}

	return (
		<MDBContainer className="pt-3">
			<MDBRow>
				<MDBCol>
					<h3 className="fgprimary-gradient">editais</h3>
					<p>Não há editais ativos no momento.</p>
				</MDBCol>
			</MDBRow>
		</MDBContainer>
	);
}
