/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from "react";
import Layout from "../Layout/MasterLayout/MasterLayout";
import CardNews from "../components/CardNews/CardNews";
import CardEditais from "../components/CardEditais/CardEditais";
import Button from "../components/Button/Button";
import CardConteudo from "../components/CardConteudoButton/CardConteudo";

import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";

import imgAcontece from "../assets/images/acontece/acontece.png";
import SEO from "../components/SEO/SEO";

import '../components/css/images.css'

export default function AconteceNaEscola() {
	return (
		<Layout>
			<SEO title="Acontece na escola" lang="pt-br"/>
			<MDBContainer>
				<MDBRow>
					<MDBCol col={12} md={7} className="order-1 order-sm-0 my-auto">
						<h1 className="pageTitle fgprimary-gradient">acontece na escola</h1>
						<p className="text-justify">
							// estamos sempre construindo experiências transformadoras para
							nossos estudantes. Conecte-se conosco para acompanhar tudo o que
							acontece na Escola de Aplicação Feevale.
						</p>
					</MDBCol>
					<MDBCol
						col={12}
						md={5}
						className="py-5 text-justify order-0 order-sm-1 imagemTopo"
					>
						<img src={imgAcontece} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<CardConteudo
								key={1}
								title={"1.º Festival de Bandas Estudantis Rosetta Tharpe"}
							
								description={'A Escola de Aplicação Feevale lança o 1.º Festival de Bandas Estudantis Rosetta Tharpe com o objetivo de promover a integração entre estudantes, incentivar a prática musical e revelar novos talentos no cenário musical estudantil. O nome do Festival justifica-se pela representatividade de Rosetta Tharpe, nascida em 20 de março de 1915, conhecida como a "Madrinha do Rock and Roll".  Ela foi uma pioneira que transcendeu barreiras de gênero e raça, tornando-se uma figura influente na música gospel e no desenvolvimento do rock. Sua habilidade em combinar ritmos de blues, jazz e gospel com uma técnica inovadora de guitarra influenciou profundamente músicos como Elvis Presley, Johnny Cash, Chuck Berry, e Little Richard, entre outros ícones do rock. Nomear este festival de bandas estudantis em sua homenagem é uma celebração de seu legado de inovação, inclusão e talento, além de inspirar novos talentos a explorar e misturar diferentes gêneros musicais com a mesma paixão e originalidade que ela demonstrou. Rosetta Tharpe faleceu em 9 de outubro de 1973. '}
								borderColor={"institucional"}
								
							/>
			</MDBContainer>
			<br/>
			<CardNews />
			<CardEditais />
			<MDBContainer className="pt-5">
				<MDBRow>
					<MDBCol col={12} md={6} className="pt-4 pt-md-0">
						<h3 className="fgprimary-gradient">conecte-se com a escola</h3>
						<MDBContainer fluid className="px-0 mx-0 text-center text-md-left">
							<MDBRow className="d-inline-flex p-0 m-0">
								<MDBCol col={3} className="text-center py-3 px-0">
									<a
										href="https://twitter.com/escolafeevale"
										target="_blank"
										rel="noreferrer"
									>
										<span className="fa-stack fa-2x">
											<MDBIcon
												icon="circle fa-stack-2x"
												size="2x"
												className="fgprimary-gradient"
											/>
											<MDBIcon fab icon="twitter text-white fa-stack-1x" />
										</span>
									</a>
								</MDBCol>
								<MDBCol col={3} className="text-center py-3 px-0">
									<a
										href="https://www.instagram.com/escolafeevale"
										target="_blank"
										rel="noreferrer"
									>
										<span className="fa-stack fa-2x">
											<MDBIcon
												icon="circle fa-stack-2x"
												size="2x"
												className="fgprimary-gradient"
											/>
											<MDBIcon fab icon="instagram text-white fa-stack-1x" />
										</span>
									</a>
								</MDBCol>
								<MDBCol col={3} className="text-center py-3 px-0">
									<a
										href="https://www.facebook.com/escolafeevale"
										target="_blank"
										rel="noreferrer"
									>
										<span className="fa-stack fa-2x">
											<MDBIcon
												icon="circle fa-stack-2x"
												size="2x"
												className="fgprimary-gradient"
											/>
											<MDBIcon fab icon="facebook-f text-white fa-stack-1x" />
										</span>
									</a>
								</MDBCol>
								<MDBCol col={3} className="text-center py-3 px-0">
									<a
										href="https://open.spotify.com/user/8mdbgc7knnnwq7e7s56tid1pc?si=T2fGI3xTRBK5Y9OYE4mjXg"
										target="_blank"
										rel="noreferrer"
									>
										<span className="fa-stack fa-2x">
											<MDBIcon
												icon="circle fa-stack-2x"
												size="2x"
												className="fgprimary-gradient"
											/>
											<MDBIcon fab icon="spotify text-white fa-stack-1x" />
										</span>
									</a>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
