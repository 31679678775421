import React from "react";
import Button from "../Button/Button"
import "./CardConteudo.scss";

import {
	MDBRow,
	MDBCol,
} from "mdbreact";

import PropTypes from "prop-types";

/**
 * 	Card de valores.
 *  @param {string} bgColor: "string", style para backgound.
 *  @param {string} amount: "string", quantidade de parcelas.
 *  @param {string} value: "string", valor separado por virgula.
 */
export default function CardConteudo({
	title,
	description,
	borderColor,
	horario = null,
	img = null,
	imgLeft = false,
}) {
	CardConteudo.propTypes = {
		title: PropTypes.string,
		horario: PropTypes.string,
		description: PropTypes.string,
		borderColor: PropTypes.string,
		img: PropTypes.string,
		imgLeft: PropTypes.bool,
	};

	return (
		<>
			<MDBRow className="my-2 px-3">
				<MDBCol className={borderColor}>
					<MDBRow className="d-flex p-0 m-0">
						{imgLeft ? (
							<>
								{img && (
									<MDBCol
										col={12}
										md={4}
										className="p-0 text-center order-1 order-md-0 "
									>
										<img src={img} className="img-fluid" />{" "}
										{/* Tamanho correto da img 405x306 */}
									</MDBCol>
								)}
								<MDBCol
									col={12}
									md={img ? 8 : 12}
									className="p-4 m-0 order-1 order-md-0 text-center text-md-left"
								>
									<span className="h5 font-weight-bold">{title}</span>
									{horario != null && (
										<p className="fgsecondary-gradient mt-2 mb-0 font-weight-bold">
											{horario}
										</p>
									)}
									<p className="text-justify mt-1">
										//{" "}
										<span dangerouslySetInnerHTML={{ __html: description.replace('// ','') }} />
									</p>
								</MDBCol>
								
							</>
						) : (
							<>
								<MDBCol
									col={12}
									md={img ? 8 : 12}
									className="p-4 m-0 order-1 order-md-0 text-center text-md-left"
								>
									<span className="h5 font-weight-bold">{title}</span>
									{horario != null && (
										<p className="fgsecondary-gradient mt-2 mb-0 font-weight-bold">
											{horario}
										</p>
									)}
									<p className="text-justify mt-2">
										//{" "}
										<span dangerouslySetInnerHTML={{ __html: description.replace('// ','') }} />
									</p>
													<Button
														type="institucional"
														to={"https://www.feevale.br/s/conteudo/08e91d5a-0e30-4724-bada-2a90cf29141c/Regulamento_Festival-Rosetta-Tharpe.pdf"}
														text="Regulamento"
														
													/>
													<div style={{marginBottom:"10px"}}></div>
													<Button
														type="institucional"
														to={"https://www.feevale.br/s/conteudo/cb5b73ed-34fc-45db-960b-3f0a6912fb4d/Regulamento_prorroga%c3%a7%c3%a3o_Festival-Rosetta-Tharpe.pdf"}
														text="Alterações no Regulamento"
														
													/>
													<div style={{marginBottom:"10px"}}></div>
													<Button
														type="institucional"
														to={"https://forms.office.com/pages/responsepage.aspx?id=1a2P-ENzy0GkPs6vixPRXf1rwjp6mgpHj7FGjbsMIQlUQ0xMNk1ZQ0szVERFQTRCRkhXQkNWWlJZMy4u&route=shorturl"}
														text="Inscreva-se"
													/>
								</MDBCol>
								
								{img && (
									<MDBCol
										col={12}
										md={4}
										className="p-0 text-center order-0 order-md-1 "
									>
										<img src={img} className="img-fluid" />{" "}
										{/* Tamanho correto da img 405x306 */}
									</MDBCol>
								)}
							</>
						)}
					</MDBRow>
				</MDBCol>
			</MDBRow>
		</>
	);
}
