import React, { useState, useEffect } from "react";
import axios from "axios";

import Button from "../Button/Button";

import {
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardBody,
	MDBCardTitle,
	MDBCardImage,
	MDBCardText,
	MDBIcon,
	MDBBtn,
} from "mdbreact";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function CardNews() {
	const [noticias, setNoticias] = useState(null);

	useEffect(() => {
		axios
			.get(`https://servico.feevale.br/site/v1/noticias/0/10/escola`)
			.then((res) => {
				let result = res.data;
				setNoticias({ result });
			});
	}, []);

	// Define os botões de avanço e retorno do carousel
	const ButtonGroup = ({ next, previous, ...rest }) => {
		const {
			carouselState: { currentSlide },
		} = rest;
		if (noticias.result.length <= 3) {
			return <></>;
		} else {
			return (
				<>
					<MDBIcon
						className="text-black d-none d-md-block fgprimary-gradient"
						style={{
							position: "absolute",
							zIndex: 1000,
							top: "40%",
							left: "-4%",
							backgroundColor: "transparent",
							cursor: "pointer",
						}}
						icon="chevron-left"
						size="3x"
						onClick={() => previous()}
					/>
					<MDBIcon
						className="text-black d-none d-md-block fgprimary-gradient"
						style={{
							position: "absolute",
							zIndex: 1000,
							top: "40%",
							right: "-4%",
							backgroundColor: "transparent",
							cursor: "pointer",
						}}
						icon="chevron-right"
						size="3x"
						onClick={() => next()}
					/>
					<MDBRow
						className="d-flex d-md-none"
						style={{
							position: "absolute",
							top: "-11%",
							left: "50%",
							transform: "translateX(-42%)",
						}}
					>
						<MDBCol className="d-flex p-0 pr-4">
							<MDBBtn
								className="arrow-carrousel m-0"
								onClick={() => previous()}
							>
								<span className="d-flex">
									<MDBIcon icon="chevron-left" className="p-2" />
								</span>
							</MDBBtn>
						</MDBCol>
						<MDBCol className="d-flex p-0 pl-4">
							<MDBBtn className="arrow-carrousel m-0" onClick={() => next()}>
								<span className="d-flex">
									<MDBIcon icon="chevron-right" className="p-2" />
								</span>
							</MDBBtn>
						</MDBCol>
					</MDBRow>
				</>
			);
		}
	};

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1025 },
			items: 3,
			slidesToSlide: 3, // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 465 },
			items: 2,
			slidesToSlide: 2, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
	};

	if (noticias !== null && noticias.result.length > 0) {
		return (
			<MDBContainer>
				<MDBRow>
					<MDBCol className="px-0">
						<h3 className="fgprimary-gradient">
							o que está rolando na escola?
						</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow className={noticias.result.length <= 3 ? "pt-0" : "pt-5 pt-md-0"}>
					<MDBCol>
						<Carousel
							swipeable={true}
							draggable={false}
							showDots={false}
							responsive={responsive}
							ssr={true} // means to render carousel on server-side.
							infinite={noticias.result.length > 1 ? true : false}
							autoPlay={noticias.result.length > 1 ? true : false}
							autoPlaySpeed={5000}
							keyBoardControl={true}
							transitionDuration={500}
							arrows={false}
							renderButtonGroupOutside={true}
							customButtonGroup={<ButtonGroup />}
							containerClass="carousel-container pb-4"
						>
							{noticias?.result?.map((noticia, index) => (
								<>
									<MDBCard className="my-2 mx-1 mx-md-3 h-100">
										{noticia.midia !== null && (
											<a
												href={noticia.tituloUrl}
												target="_blank"
												rel="noreferrer"
											>
												<MDBCardImage
													className="card-img-top"
													src={noticia.midia}
													waves={false}
													style={{ maxHeight: "150px", objectFit: "cover" }}
												/>
											</a>
										)}
										<MDBCardBody className="pb-3 px-3">
											<MDBCardTitle
												className={noticia.midia == null && "mt-5 pt-5"}
											>
												<a
													href={noticia.tituloUrl}
													target="_blank"
													className="text-decoration-none colorLetter"
													rel="noreferrer"
												>
													<h4>{noticia.titulo}</h4>
												</a>
											</MDBCardTitle>
										</MDBCardBody>
										<hr className="p-0 m-0" />
										<div className="list-inline px-3">
											<MDBCardText className="text-primary align-middle py-2 list-inline-item">
												<MDBRow>
													<MDBCol className="float-left pt-2 text-mute colorLetter">
														<MDBIcon far icon="clock" />{" "}
														{new Date(noticia.dataCadastro).toLocaleDateString(
															"pt-br",
															{
																year: "numeric",
																month: "numeric",
																day: "numeric",
															}
														)}
													</MDBCol>
													<MDBCol className="float-right pr-5 mr-2">
														<Button
															type="institucional ml-5 ml-md-5"
															to={noticia.tituloUrl}
															text="saiba mais"
														/>
													</MDBCol>
												</MDBRow>
											</MDBCardText>
										</div>
									</MDBCard>
								</>
							))}
						</Carousel>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}

	return <></>;
}
